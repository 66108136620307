// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const API_URI = process.env.REACT_APP_API_URL!

export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "unknown"

export const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === "true"

export const HEADER_COLORS: "default" | "info" | "warning" | "danger" =
  (process.env.REACT_APP_HEADER_COLOR as "default" | "info" | "warning" | "danger" | undefined) ?? "default"

export const LANDING_URI = "https://www.heropay.eu"

export const CHECKOUT_COM_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_COM_PUBLIC_KEY || "unknown"
